import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import DoctorIntro from "@/common/doctor-intro";
import CircleDetails, { DrCircleGrid } from "@/doctor-page/dr-circle";
import DrEducation, {
  DrContainerGrid,
  DrEducationUl,
  DrEducationWrapperWidth,
} from "@/doctor-page/dr-education";
import { theme } from "@/GlobalStyles";
import { AltLayout } from "layouts/AltLayout";

const DrSam: Page = () => {
  return (
    <AltLayout>
      <AlternativeHeroBanner
        PageName="About Dr Sam"
        NavigateFrom="Home"
        NavigateTo="About Us"
        IntroHeader="Meet Our Doctor"
        BgHero="dr-sam-hero.jpg"
        introPara="Dr. Sam’s favorite part of pediatric dentistry is the energy and FUN
        kids bring to the office every day."
      ></AlternativeHeroBanner>
      <DoctorIntro
        image="dr-sam-headshot.jpg"
        drName="DR. SAM"
        drPost="DDS"
        description="Dr. Sam has wanted to be a dentist since she was in middle school. She has always been one of those weirdos that love teeth, so dentistry was an easy decision! She was born and raised in a small town near Buffalo, NY and didn't even know pediatric dentists existed until applying for dental school. After just a few rotations in dental school working with children and patients with special needs, Dr. Sam knew she was going to specialize in pediatric dentistry. Dr. Sam’s favorite part of pediatric dentistry is the energy and FUN  kids bring to the office every day. There’s never a dull moment at work and she loves getting to laugh with the kiddos and build long lasting relationships with them and their families!"
        backgroundColor={theme.colors.white}
        flexDirection="row"
        MobileFlexDirection="row"
      />
      <DrEducationWrapperWidth>
        <DrContainerGrid>
          <DrEducation image="education.png" heading="Educational Background">
            <DrEducationUl>
              <li>
                Received Bachelor of Science in Biology and minored in Spanish
                at St. John Fisher College
              </li>
              <li>
                Received DDS from University at Buffalo School of Dental
                Medicine
              </li>
              <li>
                Completed Pediatric Dental Residency at Riley Children&apos;s
                Hospital for Children
              </li>
              <li>
                Provided dental services on mission trips in Dominican Republic,
                Ecuador, and Rural Tennessee
              </li>
            </DrEducationUl>
          </DrEducation>
          <DrEducation image="awards.png" heading="Awards & Achievements">
            <DrEducationUl>
              <li>
                Graduated with magna cum laude honors from St. John Fisher
                College as a Science Scholar
              </li>
              <li>
                Graduated with magna cum laude honors from University at Buffalo
                School of Dental Medicine
              </li>
              <li>
                The recipient of the New York State Dental Foundation
                Scholarship
              </li>
              <li>The recipient of the the ADA Foundation Scholarship</li>
              <li>The recipient of the Women’s Dental Guild Scholarship.</li>
              <li>
                Received Master of Science in Dentistry in Pediatric Dentistry,
                Riley Hospital for Children at IU health/IUSD
              </li>
              <li>
                Completed a fellowship in Leadership Education in
                Neurodevelopmental Disabilities (LEND)
              </li>
              <li>
                Received the G.R. Baker Fellowship Award from Riley Hospital for
                Children at IU health/IUSD
              </li>
              <li>Received Board Certification in Pediatric Dentistry</li>
            </DrEducationUl>
          </DrEducation>
          <DrEducation image="membership.png" heading="Dental Memberships">
            <DrEducationUl>
              <li>American Academy of Pediatric Dentistry</li>
              <li>American Dental Association</li>
              <li>Tennessee Dental Association</li>
              <li>Bills Mafia ( Go Bills!)</li>
              <li>Diplomate of the American Board of Pediatric Dentistry</li>
            </DrEducationUl>
          </DrEducation>
        </DrContainerGrid>
      </DrEducationWrapperWidth>
      <DrCircleGrid>
        <CircleDetails
          text="What do you do outside of the dental office?"
          bgImage="kayaking.jpg"
        >
          I’m always looking for my next adventure! I love to hike, kayak,
          travel, and spend time with friends and family. If I am not traveling,
          I am probably trying out new restaurants or singing my heart out (very
          off key) at a live music venue.
        </CircleDetails>
        <CircleDetails
          text="What is your most rewarding part of your job?"
          bgImage="kids.jpg"
        >
          Seeing nervous kiddos (or parents) leave the office with confidence
          and a smile!
        </CircleDetails>
        <CircleDetails
          text="Who’s your favorite Super Hero?"
          bgImage="mini-captain-america.jpg"
        >
          Captain America
        </CircleDetails>
        <CircleDetails
          text="What's something your patients may be surprised to learn about you?"
          bgImage="half-marathon.jpg"
        >
          I have participated in a half marathon every year for the past 4 years
          (Although after all the hills at the Nashville half last year, I may
          not be doing that doozy again.)
        </CircleDetails>
        <CircleDetails
          text="What's your favorite food?"
          bgImage="ice-cream-cone.jpg"
        >
          Ice cream! I make all sorts of fun homemade ice-cream for friends and
          family, which is what inspired us to offer ice cream in the office.
        </CircleDetails>
      </DrCircleGrid>
      {/* <Gallery /> */}
    </AltLayout>
  );
};

export default DrSam;
